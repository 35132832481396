var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Form',{ref:"searchForm",staticClass:"paper-form",attrs:{"model":_vm.search,"label-width":60}},[_c('FormItem',{attrs:{"label":"试卷库"}},[_c('Select',{attrs:{"filterable":""},on:{"on-change":_vm.handlePaperBankChange},model:{value:(_vm.search.paperBankId),callback:function ($$v) {_vm.$set(_vm.search, "paperBankId", $$v)},expression:"search.paperBankId"}},_vm._l((_vm.paperBankList),function(item){return _c('Option',{key:item.paperBankId,attrs:{"value":item.paperBankId || -1}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('FormItem',{attrs:{"label":"关键字"}},[_c('Input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.search.keywords),callback:function ($$v) {_vm.$set(_vm.search, "keywords", $$v)},expression:"search.keywords"}})],1),_c('FormItem',{attrs:{"label":"创建时间"}},[_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"type":"daterange","split-panels":"","placeholder":"请选择创建时间范围","options":_vm.dateOptions,"editable":false},model:{value:(_vm.search.createTime),callback:function ($$v) {_vm.$set(_vm.search, "createTime", $$v)},expression:"search.createTime"}})],1),_c('FormItem',{attrs:{"label":"试卷类型"}},[_c('Select',{model:{value:(_vm.search.paperType),callback:function ($$v) {_vm.$set(_vm.search, "paperType", $$v)},expression:"search.paperType"}},[_c('Option',{attrs:{"value":0}},[_vm._v("固定试题")]),_c('Option',{attrs:{"value":1}},[_vm._v("随机试题")])],1)],1),_c('FormItem',[_c('Button',{staticStyle:{"padding":"4px 20px"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.searchPaper}},[_vm._v("搜索")])],1)],1),_c('Table',{staticClass:"flex-table",attrs:{"columns":_vm.columns,"data":_vm.papers.data,"loading":_vm.loading},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap",attrs:{"title":row.name}},[_vm._v(_vm._s(row.name))])]}},{key:"number",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap",attrs:{"title":row.paperNumber}},[_vm._v(_vm._s(row.paperNumber))])]}},{key:"type",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap"},[_vm._v("固定试题")])]}},{key:"use-times",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap",attrs:{"title":row.usedTimes}},[_vm._v(_vm._s(row.usedTimes))])]}},{key:"question-count",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap",attrs:{"title":row.questionAmount}},[_vm._v(" "+_vm._s(row.questionAmount)+" ")])]}},{key:"total",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap",attrs:{"title":row.totalPoints}},[_vm._v(_vm._s(row.totalPoints))])]}},{key:"creator",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap",attrs:{"title":row.creatorName}},[_vm._v(_vm._s(row.creatorName))])]}},{key:"create-time",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap",attrs:{"title":row.createTime}},[_vm._v(" "+_vm._s(_vm._f("formatTime")(row.createTime,'yyyy-MM-dd hh:mm'))+" ")])]}},{key:"opt",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"operation"},[_c('span',{on:{"click":function($event){return _vm.handlePreviewPaper(row)}}},[_vm._v("预览")]),_c('span',{on:{"click":function($event){return _vm.handleSelectPaper(row)}}},[_vm._v("选择")])])]}}])}),_c('Page',{staticStyle:{"margin-top":"10px","float":"right"},attrs:{"show-total":"","show-sizer":"","show-elevator":"","total":_vm.papers.total,"current":_vm.pageNumber + 1},on:{"on-change":_vm.changePage,"on-page-size-change":_vm.changePageSize}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }