<template>
  <Form class="exam-form" ref="examForm" :model="examForm" :rules="ruleValidate" :label-width="90">
    <FormItem label="考试名称" prop="name">
      <Input v-model="examForm.name" placeholder="请输入" />
    </FormItem>
    <FormItem label="限制时间">
      <div slot="label">
        <span style="margin-right: 4px">限制时间</span>
        <Tooltip content="是否限制考试只能在规定时间内参加">
          <JYIcon href="#icon-help" style="cursor: pointer"></JYIcon>
        </Tooltip>
      </div>
      <RadioGroup v-model="examForm.isLimitTime">
        <Radio :label="1">是</Radio>
        <Radio :label="0">否</Radio>
      </RadioGroup>
    </FormItem>
    <FormItem v-if="examForm.isLimitTime" label="开始时间" prop="startTime">
      <DateTimePicker v-model="examForm.startTime"></DateTimePicker>
    </FormItem>
    <FormItem v-if="examForm.isLimitTime" label="结束时间" prop="endTime">
      <DateTimePicker v-model="examForm.endTime"></DateTimePicker>
    </FormItem>
    <FormItem label="考试时长" prop="duration">
      <InputNumber :min="0" :step="10" :precision="0" v-model="examForm.duration"></InputNumber>
      分钟
    </FormItem>
    <FormItem label="通过条件">
      <RadioGroup v-model="examForm.hasPassCondition">
        <Radio :label="1">是</Radio>
        <Radio :label="0">否</Radio>
      </RadioGroup>
    </FormItem>
    <FormItem v-if="examForm.hasPassCondition" label="总分不低于" prop="passRate">
      <Slider v-model="examForm.passRate" :tip-format="format"></Slider>
    </FormItem>
    <FormItem label="能否重考">
      <RadioGroup v-model="examForm.permitRepeat">
        <Radio :label="1">是</Radio>
        <Radio :label="0">否</Radio>
      </RadioGroup>
    </FormItem>
    <FormItem v-if="examForm.permitRepeat" label="最大重考次数" prop="repeated">
      <InputNumber :min="0" :precision="0" v-model="examForm.repeated"></InputNumber>
    </FormItem>
    <FormItem label="考前须知" prop="beforeExam">
      <Input type="textarea" v-model="examForm.beforeExam" placeholder="请输入考前须知(最多500字)" :rows="4" :maxlength="500" />
    </FormItem>
    <FormItem label="考后须知" prop="afterExam">
      <Input type="textarea" v-model="examForm.afterExam" placeholder="请输入考后须知(最多500字)" :rows="4" :maxlength="500" />
    </FormItem>
    <Button size="small" style="margin-left: 90px" @click="handlePreStep">上一步</Button>&nbsp;&nbsp;
    <Button type="primary" size="small" @click="handleNextStep">下一步</Button>
  </Form>
</template>

<script>
import DateTimePicker from '@/components/common/DateTimePicker'

export default {
  name: 'ExamBaseInfo',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    DateTimePicker
  },
  data: function() {
    return {
      examForm: this.value,
      ruleValidate: {
        name: [
          {
            type: 'string',
            required: true,
            message: '考试名称不能为空'
          },
          {
            type: 'string',
            max: 80,
            message: '考试名称不超过 80 个字'
          }
        ],
        duration: {
          type: 'number',
          required: true,
          min: 1,
          message: '考试时长不能为 0'
        }
      }
    }
  },
  methods: {
    format(val) {
      return val + '%'
    },
    handleNextStep() {
      this.$refs.examForm.validate(valid => {
        if (!valid) {
          return
        }

        this.$emit('on-success')
      })
    },
    handlePreStep() {
      this.$emit('on-pre-click')
    }
  },
  watch: {
    examForm(newVal, oldVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="less" scoped>
.exam-form {
  width: 500px;

  button {
    width: 60px;
  }
}
</style>
