<template>
  <div class="exam-new">
    <Breadcrumb class="custom-breadcrumb" ref="breadcrumb">
      <BreadcrumbItem>课程管理</BreadcrumbItem>
      <BreadcrumbItem>课程列表</BreadcrumbItem>
      <BreadcrumbItem>课程详情</BreadcrumbItem>
      <BreadcrumbItem>发布考试</BreadcrumbItem>
    </Breadcrumb>

    <div class="content">
      <Steps :current="currentStep" style="margin: 10px">
        <Step v-for="step in STEPS" :key="step.value" :title="step.title"></Step>
      </Steps>

      <div class="step-content">
        <transition name="fade" mode="out-in">
          <PaperSelect v-if="STEPS[currentStep].title === STEP.PAPER" @on-paper-select="handlePaperSelect"></PaperSelect>
          <ExamBaseInfo
            v-else-if="STEPS[currentStep].title === STEP.BASE_INFO"
            v-model="examForm"
            @on-success="handleNextStep"
            @on-pre-click="handlePreStep"
          ></ExamBaseInfo>
          <ExamNotify
            v-else-if="STEPS[currentStep].title === STEP.NOTIFY"
            v-model="examForm"
            @on-success="handleNextStep"
            @on-pre-click="handlePreStep"
          ></ExamNotify>
          <ExamPreview
            v-else-if="STEPS[currentStep].title === STEP.PREVIEW"
            :course-id="courseId"
            :exam="examForm"
            @on-success="handleGoBack"
            @on-pre-click="handlePreStep"
          ></ExamPreview>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import PaperSelect from '@/components/manage/course/exam/PaperSelect'
import ExamBaseInfo from '@/components/manage/course/exam/ExamBaseInfo'
import ExamNotify from '@/components/manage/course/exam/ExamNotify'
import ExamPreview from '@/components/manage/course/exam/ExamPreview'
const STEP = {
  PAPER: '选择试卷',
  BASE_INFO: '填写考试基本信息',
  EXAMINEE: '添加考生',
  NOTIFY: '通知考生',
  PREVIEW: '考试预览'
}

export default {
  name: 'NewExam',
  components: { ExamPreview, ExamNotify, ExamBaseInfo, PaperSelect },
  data: function() {
    return {
      currentStep: 0,
      examForm: {
        isLimitTime: 0,
        hasPassCondition: 0,
        permitRepeat: 0,
        paper: {},
        name: '',
        startTime: '',
        endTime: '',
        duration: 0,
        passRate: 60,
        repeated: 0,
        beforeExam: '',
        afterExam: '',
        notifyType: 0,
        notifyTimeType: 0,
        notifyTime: '',
        notifyContent: ''
      }
    }
  },
  methods: {
    handleNextStep() {
      this.currentStep++
    },
    handlePreStep() {
      this.currentStep--
    },
    handleGoBack() {
      if (this.$route.path.indexOf('course') > -1) {
        //课程中发起的考试，返回课程详情
        this.$router.push(`/manage/course/detail/${this.$route.params.courseId}`)
      } else {
        //考试中发起则回到考试
      }
    },
    handlePaperSelect(paper) {
      this.examForm.paper = paper
      this.handleNextStep()
    }
  },
  computed: {
    STEPS() {
      let steps = Object.assign(STEP)
      if (this.$route.path.indexOf('course') > -1) {
        //课程中无"添加考生"步骤
        delete steps.EXAMINEE
      }
      const result = []
      let index = 0
      for (let key in steps) {
        result.push({
          value: index++,
          title: steps[key]
        })
      }
      return result
    },
    STEP() {
      return STEP
    },
    courseId() {
      return Number(this.$route.params.courseId)
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables.less';

.exam-new {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  background-color: @layout-body-background;
  overflow: auto;
  text-align: left;

  .content {
    background-color: white;
    margin-top: 10px;
    padding: 10px;
    flex: 1 1 auto;

    .step-content {
      padding: 10px;
    }
  }
}
</style>
